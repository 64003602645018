import store from '@/store'
var moment = require('moment')
export function validateEmail (email) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}

export function OnlyTimeFilterWithoutZero (dateTime) {
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  let formatTime = moment(time).format(formate)
  formatTime = formatTime.toLowerCase()
  if (formatTime.indexOf('am') > 0 || formatTime.indexOf('pm') > 0) {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
    let formatTimeArray = formatTime.split(':')
    if (formatTimeArray[1].substring(0, 2) === '00') {
      formatTime = formatTimeArray[0] + ' ' + formatTimeArray[1].split(' ')[1]
    } else {
      formatTime = formatTimeArray[0] + ':' + formatTimeArray[1]
    }
    // else if (formatTimeArray[1].substring(0, 1) === '0') {
    //   formatTime = formatTimeArray[0] + ' ' + formatTimeArray[1].substring(1)
    // }
  } else {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
  }
  return formatTime
}
