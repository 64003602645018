<template>
  <SpecificCompanyLogin ></SpecificCompanyLogin>
</template>
<script>
import SpecificCompanyLogin from './specificCompanyLogin'
export default {
  components: {
    SpecificCompanyLogin
  },
  data () {
    return {
    }
  },
  created () {
  }
}
</script>
